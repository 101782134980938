import React from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import parse from 'html-react-parser';
import FileSaver from 'file-saver';
import * as Tesseract from 'tesseract.js';
import {Avatar, Space} from 'antd';
import TextLoader from './components/ContentLoaders/TextLoader';
import {
  localStorageKeysConstants,
  TRZ_TERMS,
  userRolesConstants,
} from './constants';
import supportedCountries from './i18n/supported-countries.json';
import supportedCurrencies from './i18n/supported-currencies_dict.json';
import {helpers as servicesHelpers} from './state/services/helpers';
import {parsePhoneNumber} from 'libphonenumber-js';
import {CircleFlag} from 'react-circle-flags';
import TableSettingsButton from './components/pages/SubscriptionsPage/TableSettingsButton';
import {firebaseAnalytics} from './snippets/firebase';

const {ADMIN, MANAGER, SUPPORT, USER} = userRolesConstants;
const {COMPANY_ID, TOKEN} = localStorageKeysConstants;

const defaultStatus = {
  key: null,
  color: '#ededed'
};

const userRoles = {
  [ADMIN]: 'userRoles.admin',
  [MANAGER]: 'userRoles.manager',
  [USER]: 'userRoles.user',
  [SUPPORT]: 'userRoles.support',
}

export const UserRoleOptions = (excludeSupport = true) => {
  const [t] = useTranslation('main');
  const roles = Object.entries(userRoles);
  let excludedRoles = [MANAGER];
  if (excludeSupport) excludedRoles.push(SUPPORT);
  return roles
    .filter(r => !excludedRoles.includes(parseInt(r[0])))
    .map(role => {
    const value = role[0];
    return {
      value,
      label: t(role[1])
    }
  });
};

export const EmailRegexp =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ZipRegexp = /^[a-zA-Z0-9][A-Za-z0-9\- ]{4,10}$/;

export const TaxRegexp = /^[A-Z0-9-.]{4,18}$/;

export const PositiveDoubleRegexp = /^[0-9]+([.,][0-9]+)?$/;

export const NameRegexp = /^(?=[a-zA-Za-åa-ö-w-яА-ЩЬЮЯҐЄІЇа-щьюяґєії0-9!@#$%&*)(+=._ \-']{2,50}$)(?!.*[_.]{2})[^_.].*[^_.]$/;

export const OptionsList = (optionsArray, activeValue, emptyLabel) => {
  const [t] = useTranslation('main');
  let options = Object.entries(optionsArray);
  if (emptyLabel) {
    options = [
      [null, emptyLabel],
      ...options
    ];
  }
  return options.map(option => {
    let value = option[0];
    let intValue = parseInt(value);
    if (value === `${intValue}`) value = intValue;
    const label = option[1];
    return {
      isActive: activeValue === value,
      value,
      label: t(label)
    }
  });
}

export const helpers = {
  additionalTransactionsGrouping: (transactions, summarizeKeyName) => transactions.map((t, key) => (
    (key !== 0 && key !== transactions.length - 1 && transactions[key + 1][summarizeKeyName])
      ? {...t, isLatest: true} : t)
  ),
  arraysIsEqual: (arr1, arr2) => {
    try {
      return JSON.stringify(arr1) === JSON.stringify(arr2)
    } catch (e) {
      return false
    }
  },
  b64toBlob: (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  },
  hasExtensions: (filename, extensions) => {
    return (new RegExp('(' + extensions.join('|').replace(/\./g, '\\.') + ')$')).test(filename);
  },
  getUrlFilename: (url, fileNameMaxLength = 18) => {
    let filename = url.replace(/#.*$/, '').replace(/\?.*$/, '').split('/');
    filename = filename[filename.length - 1];
    if (filename.length > fileNameMaxLength) {
      const splitFileName = filename.split('.');
      filename = `${splitFileName[0].slice(0, fileNameMaxLength)}...${splitFileName[1]}`;
    }
    return filename;
  },
  checkIfAvailableFeature: (feature) => {
    const availableFeatures = process.env.REACT_APP_AVAILABLE_FEATURES;
    if (availableFeatures) {
      const features = process.env.REACT_APP_AVAILABLE_FEATURES.split(',');
      return features.includes(feature);
    } else {
      return false;
    }
  },
  cutString: (string, maxLengthName = 15) =>
    string ? string.length <= maxLengthName ? string : `${string.substring(0, maxLengthName)}...` : '',
  cutFileData: (img) => {
    let cutFile = null;
    if (img !== null) {
      let index = img.lastIndexOf(',');
      cutFile = index > 0 ? img.substring(index + 1) : img;
    }
    return cutFile;
  },
  findStringDifference: (str1, str2) => str2[[...str1].findIndex((el, index) => el !== str2[index])],
  generateRandomColor: () => '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'),
  getBase64: (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  }),
  getCapitalized: (word) => word ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : word,
  getClassName: (cName, className) => {
    if (className) cName += ` ${className}`;
    return cName;
  },
  getCompanyAddress: (data) => {
    const {address, zip_code: zip, city, country} = data;
    const getProp = (prop, useComma = false) => prop ? `${prop}${useComma ? ',' : ''}` : '';
    const countryName = helpers.getCapitalized(getProp(country));
    return `${getProp(address, true)} ${getProp(zip)} ${getProp(city, true)} ${countryName}`;
  },
  getCurrentDate: () => {
    const date = moment(new Date());
    const year = parseInt(date.format('YYYY'));
    const month = parseInt(date.format('M'));
    const day = parseInt(date.format('D'));
    return { date, year, month, day };
  },
  getSupportedCountries: () => supportedCountries.sort((a, b) => a.name.localeCompare(b.name)),
  getCountryByCode: (code, useDefault = false, defaultKey = 'it') => {
    let country = null;
    let activeCountry = supportedCountries.filter(c => c['ISO3166-1-Alpha-2'].toLowerCase() === code.toLowerCase());
    if (activeCountry.length > 0) {
      country = activeCountry[0]
    } else {
      if (useDefault) {
        let activeCountry = supportedCountries.filter(c => c['ISO3166-1-Alpha-2'].toLowerCase() === defaultKey.toLowerCase());
        if (activeCountry.length > 0) country = activeCountry[0];
      }
    }
    return country;
  },
  getCountryCodeByName: (name) => {
    if (typeof name === 'string') name = name.toLowerCase();
    let activeCountry = supportedCountries.find(c => c.name.toLowerCase() === name);
    return activeCountry ? activeCountry['ISO3166-1-Alpha-2'].toLowerCase() : '';
  },
  getCurrencyCode: (currency) => {
    currency = currency.toUpperCase();
    return helpers.getObjProp(supportedCurrencies, currency, 'EUR');
  },
  getCountryName: (countryCode) => {
    let country = helpers.getCountryByCode(countryCode);
    return country ? country.name : countryCode
  },
  getDate: (date, format) => moment(date, 'YYYY-MM-DD').format(format),
  getDateFromISO: (date, format) => moment(date, 'YYYY-MM-DDTHH:mm:ssZ').format(format),
  getDateWithMonth: (date, format = 'DD MMM YYYY') => moment(date, 'YYYY-MM-DD').format(format),
  getDateWithGMT: (date) => {
    let newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
    return newDate;
  },
  getDateWithGMTFormat: (date, format) => helpers.getDate(helpers.getDateWithGMT(date), format),
  getFloatValue: (value) => value ? parseFloat(value) : 0,
  getTimestampDate: (date, format) => {
    date = String(date).split('.')[0];
    if (date.length === 10) date = date * 1000;
    return moment(date).format(format);
  },
  getTimestampDateObject: (date) => {
    date = String(date).split('.')[0];
    if (date.length === 10) date = date * 1000;
    return moment(date).toDate();
  },
  getInitials: (string, useOneInitialLetter = false) => {
    if (string === null || string === undefined) {
      return '';
    }
    let names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase(),
      namesLength = names.length;

    if (namesLength > 1) {
      initials += names[namesLength - 1].substring(0, 1).toUpperCase();
    } else if (namesLength === 1 && !useOneInitialLetter) {
      initials += string.slice(1, string.length - 1).substring(0, 1).toUpperCase()
    }
    return initials;
  },
  getMobileAppPath: (path) => {
    const releaseChannel = process.env.REACT_APP_MOBILE_APP_RELEASE_CHANNEL;
    const additionalPath = releaseChannel ? `?release-channel=${releaseChannel}` : '';
    const link = process.env.REACT_APP_MOBILE_APP_LINK;
    const linkPath = `/${path}${additionalPath}`;
    return link ? `${link}/--${linkPath}` : `h1card:/${linkPath}`;
  },
  getWebsocketUrl: (variant) => {
    const socketUrls = {
      authentication: process.env.REACT_APP_AUTHENTICATION_WEBSOCKET_ENDPOINT,
      backend: process.env.REACT_APP_WEBSOCKET_ENDPOINT
    }
    return socketUrls[variant] || undefined;
  },
  getAuthDetails: () => ({
    companyId: localStorage.getItem(COMPANY_ID),
    token: helpers.getObjProp(JSON.parse(localStorage.getItem(TOKEN)), 'accessToken')
  }),
  getWebsocketOptions: () => ({
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  }),
  getMoneyView: (value, countryCode = undefined, currencyCode = undefined, showCurrencyCode = true, showFractionalPart = true) => {
    let curCode = 'EUR';
    let separatorId;
    if (!helpers.isNaV(currencyCode)) {
      curCode = currencyCode;
    } else if (countryCode) {
      let country = helpers.getCountryByCode(countryCode)
      if (country) {
        separatorId = country.separator_id;
        curCode = country.currency_alphabetic_code;
      }
    }
    const cCodeObj = helpers.getCurrencyCode(curCode);
    const cCode = parse(cCodeObj.symbol);
    const priceSymbol = value < 0 ? '-' : '';
    const price = helpers.getPrice(Math.abs(value), separatorId, showFractionalPart);
    if (showCurrencyCode) {
      return cCodeObj.position === 'after' ? <>{priceSymbol}{price}{cCode}</> : <>{priceSymbol}{cCode}{price}</>;
    } else {
      return price;
    }
  },
  getObjProp: (obj, propName, defaultValue = '') => {
    if (obj && obj.hasOwnProperty(propName)) {
      return obj[propName]
    } else {
      return defaultValue
    }
  },
  getPrice: (value, separatorId, showFractionalPart = true) => {
    const parts = Number(value).toFixed(2).toString().split('.');
    let str, separator;
    const integerPart = parts[0];
    const fractionalPart = parts[1];
    switch(separatorId) {
      case 'SID02':
        str = helpers.separateString(integerPart, ',');
        separator = '.';
        break;
      case 'SID03':
        str = helpers.separateString(integerPart, ' ');
        separator = '.';
        break;
      case 'SID04':
        str = helpers.separateString(integerPart, '.');
        separator = ',';
        break;
      case 'SID01':
      default:
        str = helpers.separateString(integerPart, ' ');
        separator = ',';
        break;
    }

    return !showFractionalPart && fractionalPart === "00" ? str : `${str}${separator}${fractionalPart}`;
  },
  getStatus: (statusKey, statusType) => statusType.hasOwnProperty(statusKey) ? statusType[statusKey] : defaultStatus,
  getColorByIndex: (index) => {
    return {
      0: '#52C41A',
      1: '#1890FF',
      2: '#722ED1',
      3: '#FF7875',
      4: '#03AB93',
      5: '#EABD3B',
    }[index] || helpers.generateRandomColor();
  },
  getUserRole: (key) => userRoles.hasOwnProperty(key) ? userRoles[key]: 'Undefined user role',
  getMomentUnixTimestamp: (date) => date.unix(),
  hex2rgba: (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  },
  hideCardNumber: (cardNumber, symbolsLength = 8) => {
    if (cardNumber) {
      const len = cardNumber.length;
      return cardNumber ? cardNumber.substring(len - symbolsLength, len) : '';
    } else {
      return ''
    }
  },
  isEmptyObject: (obj) => helpers.arraysIsEqual(obj, {}),
  isAdmin: (userRole) => [ADMIN, SUPPORT].includes(userRole),
  isManager: (userRole) => [ADMIN, MANAGER, SUPPORT].includes(userRole),
  isSupport: (userRole) => [SUPPORT].includes(userRole),
  isNaV: (value) => [null, undefined].includes(value),
  range: (start, end) => (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start),
  reverse: (str) => str.split('').reverse().join(''),
  separateString: (str, separateSymbol) => {
    str = helpers.reverse(str);
    str = str.match(/.{1,3}/g).join(' ');
    str = helpers.reverse(str);
    if (separateSymbol !== ' ') {
      str = str.replaceAll(' ', separateSymbol);
    }
    return str;
  },
  showContentWithLoader: (isEnable, content, loader = <TextLoader />) => isEnable ? content : loader,
  checkAvailableTokenTTL: (token, needParse = true) => {
    const data = needParse ? servicesHelpers.parseJWT(token) : token;
    const ttl = helpers.getObjProp(data, 'token_ttl', 0);
    return ttl ? new Date().getTime() < ttl * 1000 : false;
  },
  getSecondsTime: (seconds) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return moment(date).format('mm:ss');
  },
  getUpdatedBatchExpensesList: (expenses, updatedExpense, actionType) => {
    return expenses.map(expense => {
      if (expense.id !== updatedExpense.id) {
        return expense;
      } else {
        let expenseObj = {
          ...expense,
          ...updatedExpense
        }
        if (actionType !== 'attachment') {
          expenseObj = {
            ...expenseObj,
            budget: updatedExpense.budget,
            fund: updatedExpense.fund,
            description: updatedExpense.description,
            tags: updatedExpense.tags.length > 0,
            tags_list: updatedExpense.tags_list
          }
        }
        return expenseObj;
      }
    });
  },
  recognizeImageToText: (image, successCallback, errorCallback) => {
    Tesseract.recognize(image, "eng")
      .then((result) => successCallback && successCallback(result))
      .catch((err) => errorCallback && errorCallback(err));
  },
  getTextDigits: (text) => text.replace(/\D/g,''),
  getVisibleClassName: (className = undefined, isVisible = false) => {
    let cName = 'form-step-container';
    if (className) cName += ` ${className}`;
    if (!isVisible) cName += ' form-d-none';
    return cName;
  },
  saveFile: (data) => {
    const blob = helpers.b64toBlob(data.file, data['content-type']);
    FileSaver.saveAs(blob, data.filename);
  },
  isValidUrl: (url) => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return regex.test(url);
  },
  getEmployeeOptions: ({employees, employeeEmail, t}) => {
    return employees.map(employee => {
      const {email, logo, full_name: name} = employee;
      return {
        value: email,
        label: (
          <Space className='d-flex-child'>
            <Avatar src={logo || undefined}>
              {helpers.getInitials(name)}
            </Avatar>
            {`${name}${employeeEmail === email ? ` (${t('main:you')})` : ''}`}
          </Space>
        ),
        optionLabel: name
      }
    })
  },
  getFormServerErrorFields: (errors) => {
    let fields = [];
    if (errors.hasOwnProperty('errors')) {
      const formErrors = errors.errors;
      fields = Object.keys(formErrors).map(key => ({
        name: key,
        errors: [formErrors[key]]
      }));
    }
    return fields;
  },
  getFormLogoErrorField: (errors, logoFieldName) => {
    let field = {};
    let errorMessage = errors?.message;
    if (errorMessage === 'Can not detect the mime type') {
      field = {name: logoFieldName, errors: [errorMessage]};
    }
    return field;
  },
  getLocationHash: (location) => {
    let {hash} = location;
    if (hash) {
      hash = hash.replace('#', '');
    }
    return hash;
  },
  getFormattedPhoneNumber: (number) => {
    try {
      return parsePhoneNumber(number).formatInternational();
    } catch (e) {
      return undefined;
    }
  },
  getCountryOptions: (propName = 'ISO3166-1-Alpha-2') => {
    const countries = helpers.getSupportedCountries();
    return countries.map(c => {
      let flagCode = '';
      let value = '';

      try {
        value = c[propName];
        flagCode = c['ISO3166-1-Alpha-2'];
      } catch (e) {
        const alphaThreeCode = c['ISO3166-1-Alpha-3'];
        if (alphaThreeCode) {
          flagCode = value = alphaThreeCode;
        }
      }

      return {
        label: c.name,
        value: value,
        image: (
          <CircleFlag
            countryCode={flagCode.toLowerCase()}
            height={22}
          />
        )
      }
    });
  },
  getHash: (location) => {
    let hash = null;
    if (location) {
      hash = location.hash.replace('#', '').split('?')[0];
    }
    return hash;
  },
  updateMenuItems: ({location, items, selectedKeys, setSelectedKeys}) => {
    let item = items.find(el => el.path === location.pathname);
    let updatedSelectedKeys = item ? [item.key] : [];
    if (!helpers.arraysIsEqual(updatedSelectedKeys, selectedKeys)) setSelectedKeys(updatedSelectedKeys);
  },
  getSupportedFormatsList: (formatsList) => {
    let formats = null;
    let pattern = /\.([0-9a-z]+)(?:[?#]|$)/i;
    if (formatsList) {
      formats = formatsList.split(',')
        .map(a => a.match(pattern))
        .map(f => f ? f.length === 2 ? f[1] : null : null)
        .filter(f => f)
        .map(f => f.toUpperCase())
        .join(', ');
    }
    return formats;
  },
  getUpdatedFormValues: ({initialValues, submittedValues, excludedFields = []}) => {
    let values = {};
    Object.keys(submittedValues).forEach(key => {
      if (excludedFields.includes(key)) return;
      const submittedValue = submittedValues[key];
      const initialValue = initialValues[key];
      const isEqual = typeof submittedValue === 'object'
        ? JSON.stringify(submittedValue) === JSON.stringify(initialValue)
        : submittedValue === initialValue;
      if (!isEqual) values = {...values, [key]: submittedValues[key]};
    });
    return values;
  },
  getTrzTermsUrl: (locale) => {
    const defaultLink = TRZ_TERMS.default;
    let termsLink;
    if (locale && typeof locale === 'string') {
      locale = locale.toUpperCase();
      termsLink = TRZ_TERMS.hasOwnProperty(locale) ? TRZ_TERMS[locale] : TRZ_TERMS.default
    } else {
      termsLink = defaultLink;
    }
    return termsLink;
  },
  getElementOffsetTop: (elementId, parentsCount = 0) => {
    let element = document.querySelector(elementId);
    let offsetTop = element?.offsetTop || 0;
    if (parentsCount && parentsCount > 0) {
      for (let i = 0; i < parentsCount; i++) {
        if (element && element.offsetParent) {
          element = element.offsetParent;
          offsetTop += element.offsetTop;
        }
      }
    }
    return offsetTop;
  },
  stopPropagation: (event) => event && event.stopPropagation(),
  trimAllValues: (fields) => {
    return Object.keys(fields).reduce((prev, next) => Object.assign(prev, {
      [next]: fields[next].trim()
    }), {});
  },
  getTagOptions: (tags) => tags.map(t => ({label: t.tag_name, value: t.tag_name})),
  getSettingsRightSideContent: ({
    alwaysAvailableColumnKeys = [],
    columns = [],
    onChange,
    selectedKeys,
    rightSideContent,
  }) => {
    columns = columns.filter(column => !alwaysAvailableColumnKeys.includes(column.dataIndex));
    return (
      <TableSettingsButton
        defaultSelectedKeys={selectedKeys}
        options={columns.map(c => ({value: c.dataIndex, label: c.title}))}
        onChange={onChange}
      >
        {rightSideContent}
      </TableSettingsButton>
    )
  },
  getAvailableTableColumns: ({
    alwaysAvailableColumnKeys = [],
    columns = [],
    selectedColumns = []
  }) => {
    const columnKeys = [...selectedColumns, ...alwaysAvailableColumnKeys];
    return columns.filter(column => columnKeys.includes(column?.dataIndex));
  },
  getCardsOptions: (cards, symbolsLength) => cards
    .filter(card => card.masked_pan)
    .map(card => ({label: helpers.hideCardNumber(card.masked_pan, symbolsLength), value: card.masked_pan, id: card.id})),
  filterCardOption: (input, option) => {
    const clearCardValue = (value) => value ? value.replaceAll('*', '') : '';
    return clearCardValue(option.label).indexOf(clearCardValue(input)) >= 0;
  },
  getSearchParams: (params = {}) => {
    let value;
    let query = {};
    Object.keys(params).forEach(key => {
      value = params[key];
      if (value !== '' && !helpers.isNaV(value)) {
        query = { ...query, [key]: value };
      }
    });
    return query;
  },
  getSearchParamsFromLocation: (location) => {
    const {hash, search} = location;
    let query = search;
    if (hash) {
      const spittedUrl = hash.split('?');
      query = spittedUrl.length > 1 ? spittedUrl[1] : '';
    }
    return Object.fromEntries(new URLSearchParams(query));
  },
  logEvent: (eventName, eventParams) => firebaseAnalytics.logEvent(eventName, eventParams)
}
